import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../types';

export type YourItemsState = {
    addons: any;
    countById: { [id: string]: number };
    priceById: { [id: number]: number };
    itemIds: any[];
    products: any;
};

const initialState: YourItemsState = {
    addons: {},
    countById: {},
    priceById: {},
    itemIds: [],
    products: [],
};

export const getStartedSlice = createSlice({
    name: 'your-addons',
    initialState,
    reducers: {
        setItems(state, { payload }: PayloadAction<{ [id: number]: number }>) {
            state.countById = payload;
            state.itemIds = Object.keys(payload).map((v) => parseInt(v, 10));
        },
        setItemPrices(
            state,
            { payload }: PayloadAction<{ [id: number]: number }>
        ) {
            state.priceById = Object.keys(payload).map((v) => parseInt(v, 10));
        },
        incrementItem(state, { payload }: PayloadAction<any>) {
            if (state.itemIds.includes(payload)) {
                state.countById[payload] += 1;
            } else {
                state.countById[payload] = 1;
                state.itemIds.push(payload);
            }
        },
        decrementItem(state, { payload }: PayloadAction<any>) {
            state.countById[payload] -= 1;
            if (state.countById[payload] <= 0) {
                delete state.countById[payload];
                state.itemIds.splice(state.itemIds.indexOf(payload));
            }
            state.products.forEach((e: any) => {
                if (e.product_id === payload.id) {
                    e.quantity = 0;
                }
            });
        },
        setItemCount(
            state,
            { payload }: PayloadAction<{ id: string; count: number }>
        ) {
            const { id, count } = payload;
            if (count === 0) {
                if (state.itemIds.includes(id))
                    state.itemIds.slice(state.itemIds.indexOf(id));
                if (state.products.some((e: any) => e.product_id === id)) {
                    state.products.forEach((e: any) => {
                        if (e.product_id === id) {
                            e.quantity = count;
                        }
                    });
                } else {
                    state.products.push({ product_id: id, quantity: 0 });
                }
            } else {
                if (!state.itemIds.includes(id)) state.itemIds.push(id);
                state.countById[id] = count;
                if (state.products.some((e: any) => e.product_id === id)) {
                    state.products.forEach((e: any) => {
                        if (e.product_id === id) {
                            e.quantity = count;
                        }
                    });
                } else {
                    state.products.push({ product_id: id, quantity: count });
                }
            }
        },
    },
});

export default getStartedSlice.reducer;

export const { incrementItem, decrementItem, setItemCount } =
    getStartedSlice.actions;

const selectSelf = (state: RootState) => state.yourItems;

export const selectItemsIds = createSelector(selectSelf, (s) => s.itemIds);
export const selectCountById = createSelector(selectSelf, (s) => s.countById);
export const selectPriceById = createSelector(selectSelf, (s) => s.priceById);
export const selectItemCount = createSelector(
    selectCountById,
    (_state: any, id: string) => id,
    (countById, id) => countById[id]
);
export const selectProducts = createSelector(selectSelf, (s) => s.products);

export const selectItemSubTotal = createSelector(
    selectCountById,
    selectPriceById,
    (_state: any, id: number) => id,
    (countById, priceById, id) => countById[id] * priceById[id]
);
export const selectTotalCount = createSelector(selectCountById, (s) =>
    Object.values(s).reduce((a, b) => a + b, 0)
);
export const selectSubTotal = createSelector(
    selectCountById,
    selectPriceById,
    (countById, priceById) =>
        Object.entries(countById)
            .map(([id, count]) => count * priceById[parseInt(id, 10)])
            .reduce((a, b) => a + b, 0)
);
