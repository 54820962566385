import * as Select from '@radix-ui/react-select';
import clsx from 'clsx';
import { useState } from 'react';
import { ChevronDownIcon } from '@/components/common/Icons';
import Option from '@/types/Option';

type SelectProps = {
    label?: string;
    value?: string;
    className?: string;
    onChange(value: string): void;
    options: Option[];
    defaultValue?: string;
};

export default function AccordionSelect(props: SelectProps) {
    const { options, value, label, onChange, className, defaultValue } = props;
    const [open, setOpen] = useState(false);

    return (
        <Select.Root
            open={open}
            onOpenChange={setOpen}
            value={value}
            onValueChange={onChange}
            defaultValue={defaultValue}
        >
            <div
                className={clsx(
                    'group flex flex-wrap items-center justify-center p-3 focus:outline-none',
                    className
                )}
            >
                {label && <span>{label}</span>}
                <Select.Trigger className="ml-2 flex items-center py-2 pl-2 font-medium text-primary underline hover:bg-gray-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-primary">
                    <Select.Value />
                    <Select.Icon>
                        <ChevronDownIcon
                            className="mx-2 -mb-1 h-3 w-3"
                            aria-hidden="true"
                        />
                    </Select.Icon>
                </Select.Trigger>
            </div>

            <Select.Portal>
                <Select.Content className="h-fit overflow-hidden border border-secondary bg-white shadow-block">
                    <Select.ScrollUpButton>
                        <ChevronDownIcon className="rotate-90" />
                    </Select.ScrollUpButton>
                    <Select.Viewport>
                        {options?.map((option) => (
                            <Select.Item
                                key={option.id}
                                value={option.value}
                                className="text-medium m-1 cursor-pointer select-none truncate px-2 py-1 text-xl font-normal text-primary highlighted:outline highlighted:outline-2 highlighted:outline-primary checked:font-semibold"
                            >
                                <Select.ItemText>
                                    {option.label}
                                </Select.ItemText>
                            </Select.Item>
                        ))}
                    </Select.Viewport>
                    <Select.ScrollDownButton>
                        <ChevronDownIcon />
                    </Select.ScrollDownButton>
                </Select.Content>
            </Select.Portal>
        </Select.Root>
    );
}
