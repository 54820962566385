import * as RadioGroup from '@radix-ui/react-radio-group';
import clsx from 'clsx';
import { useState } from 'react';
import { CheckIcon } from '@/components/common/Icons';
import Modal from '@/components/modals/Modal';
import ModalBoxSizeContent from '@/components/modals/ModalBoxSizeContent';

type Props = {
    title: string;
    heading: string;
    value: string;
    id: string;
    className?: string;
    description?: string;
    childPackagesName?: string;
    modal?: boolean;
    children?: React.ReactNode;
};

export default function BoxSizeRadio({
    title,
    heading,
    children,
    className,
    description,
    modal,
    value,
    childPackagesName,
    id,
}: Props) {
    const [showModal, setShowModal] = useState<boolean>(false);
    return (
        <>
            <RadioGroup.Item
                id={id}
                value={value}
                className={clsx(
                    'group m-2.5 flex w-full max-w-md cursor-pointer flex-col border-2 border-black focus:outline-none focus-visible:outline-4 focus-visible:outline-primary checked:shadow-block',
                    className
                )}
            >
                <div className="flex w-full items-center justify-between bg-black transition-colors group-hover:bg-darkenPrimary group-checked:bg-primary">
                    <h3 className="m-5 font-bold uppercase text-white">
                        {heading}
                    </h3>
                </div>
                <div className="flex w-full flex-1 flex-col justify-between px-5 py-6 text-left">
                    <div className="pb-4 text-3xl font-bold uppercase">
                        {title}
                    </div>
                    <div className="flex flex-row items-end justify-between">
                        <div className="flex-1">
                            {children}
                            {modal && (
                                <div
                                    id={value}
                                    tabIndex={0}
                                    role="button"
                                    className="mt-4 inline-block font-neue text-xs font-normal uppercase tracking-widest underline transition-colors hover:text-primary"
                                    onClick={() => setShowModal(true)}
                                    onKeyDown={(event) => {
                                        if (
                                            event.key === 'enter' ||
                                            event.key === 'space'
                                        )
                                            setShowModal(true);
                                    }}
                                >
                                    Learn more
                                </div>
                            )}
                        </div>
                        <div className="relative m-2 h-10 w-10 border border-black">
                            <RadioGroup.Indicator>
                                <CheckIcon className="absolute -top-4 -right-5 h-14 w-14" />
                            </RadioGroup.Indicator>
                        </div>
                    </div>
                </div>
            </RadioGroup.Item>
            <Modal
                title={title}
                open={showModal}
                onClose={() => setShowModal(false)}
            >
                <ModalBoxSizeContent
                    description={description}
                    packageId={id}
                    childPackagesName={childPackagesName}
                />
            </Modal>
        </>
    );
}
