import * as RadioGroup from '@radix-ui/react-radio-group';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spring, animated } from 'react-spring';
import RedirectAfterErrorModal from '@/components/modals/RedirectAfterErrorModal';
import useHandleSubmit from '@/hooks/useHandleSubmit';
import useWindowDimensions from '@/hooks/useWindowDimensions';
import { boxSizeFormSchema } from '@/models/BoxSizeFormModel';
import ProductModel from '@/models/ProductModel';
import { subscribeAddonsFormSchema } from '@/models/SubscribeAddonsFormModel';
import { subscribeProductFormSchema } from '@/models/SubscribeProductFormModel';
import { updateCustomBoxTypeFormSchema } from '@/models/UpdateCustomBoxTypeFormModel';
import {
    useModifyPackages,
    useSubscribeAddons,
    useSubscribeProduct,
} from '@/services/deliveryZoneService';
import { useKlaviyoUpdateItems } from '@/services/klaviyo';
import { useModifyCustomBox } from '@/services/subscriptionsService';
import { selectCurrentStep } from '@/store/getStarted/getStartedSlice';
import { useUser } from '@/store/user/userSlice';
import {
    resetItems,
    selectProducts,
    selectSubTotal,
    selectTotalCount,
    setItemCount,
    setItemPrices,
} from '@/store/yourItems/yourItemsSlice';
import formatDollarAmount from '@/utils/formatDollarAmount';
import Button from '../common/Button';
import { CheckThickerIcon, ChevronDownIcon } from '../common/Icons';
import SummaryItem from '../common/SummaryItem';

type Props = {
    title?: string;
    size?: number;
    onSizeChange?(size: any): void;
    items?: ProductModel[];
    allItems?: any;
    boxPrice?: number;
    packages?: any;
    subscriptionId: string;
    packageId?: string;
    setPackageId?(id: string): any;
    date?: string;
    btnText: string;
    packageInfo?: any;
    products?: any;
    disabled?: any;
    removeCart?: any;
};
export default function SummaryCard({
    title,
    size,
    onSizeChange,
    items,
    allItems,
    boxPrice,
    packages,
    subscriptionId,
    packageId,
    setPackageId,
    date,
    btnText,
    packageInfo,
    products,
    disabled,
    removeCart,
}: Props) {
    const [user] = useUser();
    const dispatch = useDispatch();
    const maxCount = size;
    const [expanded, setExpanded] = useState(false);
    const { height: windowHeight } = useWindowDimensions();
    const totalCount = useSelector(selectTotalCount);
    const subTotal = useSelector(selectSubTotal);
    const productPayload = useSelector(selectProducts);
    const currentStep = useSelector(selectCurrentStep);

    const { mutateAsync: subscribeProduct, error: subscribeProductError } =
        useSubscribeProduct(subscriptionId, { choices: productPayload });

    const { mutateAsync: subscribeAddons, error: subscribeAddonsError } =
        useSubscribeAddons(subscriptionId, { choices: productPayload });

    const { mutateAsync: modifyPackages } = useModifyPackages(subscriptionId, {
        frequency: user?.subscription?.frequency,
        package: packageId,
    });

    const { mutateAsync: modifySubscribeProduct, error: modifyProductError } =
        useSubscribeProduct(subscriptionId, {
            choices: productPayload,
            menu: date!,
        });

    const { mutateAsync: modifySubscribeAddons, error: modifyAddonsError } =
        useSubscribeAddons(subscriptionId, {
            choices: productPayload,
            menu: date!,
        });

    const {
        mutateAsync: modifyCustomBoxProduct,
        error: modifyCustomBoxProductError,
    } = useModifyCustomBox(subscriptionId, {
        root: localStorage.getItem('root'),
        package: localStorage.getItem('packageId'),
        choices: productPayload,
    });

    const klaviyoUpdateItems = useKlaviyoUpdateItems;

    useEffect(() => {
        dispatch(resetItems());
        if (allItems) {
            Object.entries(allItems).forEach((e: any) => {
                if (
                    e[1].selection?.maximum !== null &&
                    e?.[1].selection?.quantity > e?.[1].selection?.maximum
                ) {
                    dispatch(
                        setItemCount({
                            id: e[0],
                            count: e?.[1].selection?.maximum,
                        })
                    );
                } else if (
                    currentStep.index === 4.1 &&
                    e?.[1].selection?.maximum !== null &&
                    e?.[1].selection?.quantity > e?.[1].selection?.maximum
                ) {
                    dispatch(
                        setItemCount({
                            id: e[0],
                            count: e?.[1].inventory,
                        })
                    );
                } else {
                    dispatch(
                        setItemCount({
                            id: e[0],
                            count:
                                e[1].selection?.quantity !== 0
                                    ? e[1].selection?.quantity
                                    : 0,
                        })
                    );
                }
                if (e[1].extras) {
                    dispatch(
                        setItemPrices({
                            id: e[0],
                            price: e[1].price,
                            extras: e[1].extras,
                        })
                    );
                } else {
                    dispatch(setItemPrices({ id: e[0], price: e[1].price }));
                }
            });
        }
    }, [allItems, dispatch, user?.subscription?.id, currentStep]);

    const handleSubmitProduct = useHandleSubmit(
        subscribeProductFormSchema,
        (e: any) => subscribeProduct(e),
        () => {
            localStorage.setItem('addProductsToCart', 'true');
            if (!window.Echo.socketId()) {
                window.location.href = '/get-started/addons';
            }
        },
        dispatch
    );

    const handleSubmitModifyProduct = useHandleSubmit(
        subscribeProductFormSchema,
        (e: any) => modifySubscribeProduct(e),
        () => {
            klaviyoUpdateItems(user, productPayload);
            localStorage.setItem('updateSubscription', 'true');
            if (!window.Echo.socketId()) {
                window.location.href = `/dashboard/my-deliveries/subscriptions/${subscriptionId}/${date}`;
            }
        },
        dispatch
    );

    const handleSubmitAddons = useHandleSubmit(
        subscribeAddonsFormSchema,
        (e: any) => subscribeAddons(e),
        () => {
            localStorage.setItem('addAddOnsToCart', 'true');
            if (!window.Echo.socketId()) {
                window.location.href = '/get-started/checkout';
            }
        },
        dispatch
    );

    const handleSubmitModifyAddons = useHandleSubmit(
        subscribeProductFormSchema,
        (e: any) => modifySubscribeAddons(e),
        () => {
            localStorage.setItem('updateSubscription', 'true');
            if (!window.Echo.socketId()) {
                window.location.href = `/dashboard/my-deliveries/subscriptions/${subscriptionId}/${date}`;
            }
        },
        dispatch
    );

    const handleSubmitBoxProduct = useHandleSubmit(
        boxSizeFormSchema,
        (e: any) => modifyPackages(e),
        () => {
            setTimeout((e: any) => {
                subscribeProduct(e);
            }, 2000);
        },
        dispatch
    );

    const handleSubmitModifyBoxProduct = useHandleSubmit(
        updateCustomBoxTypeFormSchema,
        (e: any) => modifyCustomBoxProduct(e),
        () => {
            if (!window.Echo.socketId()) {
                window.location.href = `/dashboard/my-deliveries`;
            }
        },
        dispatch
    );

    return (
        <>
            {/* Mobile summary card */}
            <aside className="fixed right-2 left-2 bottom-[-2px] z-40 flex flex-col border border-secondary bg-white p-4 shadow-block lg:hidden">
                <button
                    className="-m-4 flex items-center justify-between p-4"
                    type="button"
                    onClick={() => setExpanded((s) => !s)}
                >
                    {size && (
                        <div className="text-2xl font-bold">
                            {totalCount}/{maxCount}
                        </div>
                    )}
                    <div className="py-1 font-bold uppercase">
                        {boxPrice ? (
                            <div>
                                {currentStep?.index === 3
                                    ? 'Add-ons Subtotal'
                                    : 'Subtotal'}{' '}
                                : {formatDollarAmount(boxPrice)}
                            </div>
                        ) : (
                            <div>
                                {currentStep?.index === 3
                                    ? 'Add-ons Subtotal'
                                    : 'Subtotal'}{' '}
                                : {formatDollarAmount(subTotal)}
                            </div>
                        )}
                    </div>
                    <ChevronDownIcon
                        className={clsx('h-3 w-6', !expanded && 'rotate-180')}
                    />
                </button>
                <Spring
                    to={{
                        height: expanded ? windowHeight - 118 : 20,
                        opacity: expanded ? 1 : 0,
                    }}
                >
                    {({ height, opacity }: any) => (
                        <animated.div
                            style={{
                                height,
                                opacity,
                            }}
                            className="flex min-h-0 flex-col overflow-hidden lg:overflow-visible"
                        >
                            {size && onSizeChange && setPackageId && (
                                <>
                                    <div
                                        role="separator"
                                        className="my-4 h-[5px] bg-secondary"
                                    />
                                    <div>
                                        <RadioGroup.Root
                                            value={`${maxCount}`}
                                            onValueChange={(value) => {
                                                onSizeChange(value);
                                                setPackageId(
                                                    packages?.filter(
                                                        (e: any) =>
                                                            e.id !== packageId
                                                    )?.[0].id
                                                );
                                                localStorage.setItem(
                                                    'packageId',
                                                    packages?.filter(
                                                        (e: any) =>
                                                            e.id !== packageId
                                                    )?.[0].id
                                                );
                                            }}
                                            className="flex justify-between space-x-6"
                                        >
                                            {packages?.map((value: any) => (
                                                <RadioGroup.Item
                                                    className="group flex items-center space-x-4"
                                                    key={value.id}
                                                    value={`${value.selection.maximum}`}
                                                >
                                                    <div className="relative h-6 w-6 border border-secondary">
                                                        <RadioGroup.Indicator>
                                                            <CheckThickerIcon className="absolute -top-1.5 left-0.5" />
                                                        </RadioGroup.Indicator>
                                                    </div>
                                                    <div className="flex flex-col text-left">
                                                        <span className="font-bold uppercase">
                                                            {value.name}
                                                        </span>
                                                        <span className="text-xs">
                                                            {
                                                                value.selection
                                                                    .maximum
                                                            }{' '}
                                                            products
                                                        </span>
                                                    </div>
                                                </RadioGroup.Item>
                                            ))}
                                        </RadioGroup.Root>
                                    </div>
                                </>
                            )}
                            <div
                                role="separator"
                                className="my-4 h-[5px] bg-secondary"
                            />
                            {items && items?.[0] && (
                                <div className="-m-4 flex flex-1 flex-col overflow-y-auto px-4">
                                    {items?.map((item, index: any) => {
                                        if (item)
                                            return (
                                                <div key={item.id}>
                                                    <SummaryItem
                                                        key={item.id}
                                                        {...item}
                                                        maxCount={
                                                            maxCount || 1000
                                                        }
                                                        maxSelection={
                                                            // eslint-disable-next-line no-nested-ternary
                                                            item.inventory !==
                                                            null
                                                                ? item.inventory! <
                                                                  item.selection
                                                                      .quantity
                                                                    ? item
                                                                          .selection
                                                                          .quantity
                                                                    : item.inventory
                                                                : item.selection
                                                                      .maximum
                                                        }
                                                        error={
                                                            (subscribeProductError as any) ||
                                                            (subscribeAddonsError as any) ||
                                                            (modifyProductError as any) ||
                                                            (modifyAddonsError as any) ||
                                                            (modifyCustomBoxProductError as any)
                                                        }
                                                        removeCart={removeCart}
                                                        product={item}
                                                    />
                                                    {items?.length !==
                                                        index + 1 && (
                                                        <div
                                                            role="separator"
                                                            className="my-1 block h-[3px] bg-secondary"
                                                        />
                                                    )}
                                                </div>
                                            );
                                        return null;
                                    })}
                                    {currentStep?.index === 3 && (
                                        <>
                                            <h3 className="mt-8 text-base font-bold uppercase">
                                                Your Box
                                            </h3>
                                            <div
                                                role="separator"
                                                className="my-1 block h-[3px] bg-secondary"
                                            />
                                            {products && products?.[0] ? (
                                                <div
                                                    className={clsx(
                                                        'flex flex-1 flex-col'
                                                    )}
                                                >
                                                    {products?.map(
                                                        (
                                                            product: any,
                                                            index: any
                                                        ) => {
                                                            if (product)
                                                                return (
                                                                    <div
                                                                        key={`product-${product.name}`}
                                                                    >
                                                                        <SummaryItem
                                                                            key={`product-${product.name}`}
                                                                            id={`product-${product.name}`}
                                                                            name={
                                                                                product.name
                                                                            }
                                                                            thumbnail={
                                                                                product.thumbnail
                                                                            }
                                                                            maxCount={
                                                                                maxCount ||
                                                                                1000
                                                                            }
                                                                            lockedCount={
                                                                                product.quantity
                                                                            }
                                                                            locked
                                                                            error={
                                                                                (subscribeProductError as any) ||
                                                                                (subscribeAddonsError as any) ||
                                                                                (modifyProductError as any) ||
                                                                                (modifyAddonsError as any) ||
                                                                                (modifyCustomBoxProductError as any)
                                                                            }
                                                                        />
                                                                        {products?.length !==
                                                                            index +
                                                                                1 && (
                                                                            <div
                                                                                role="separator"
                                                                                className="my-1 block h-[3px] bg-secondary"
                                                                            />
                                                                        )}
                                                                    </div>
                                                                );
                                                            return null;
                                                        }
                                                    )}
                                                </div>
                                            ) : (
                                                <div className="my-4 text-xs font-medium uppercase tracking-wide">
                                                    {packageInfo?.parents?.[1]}
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                            )}

                            <div
                                role="separator"
                                className="my-4 h-[5px] bg-secondary md:hidden"
                            />
                        </animated.div>
                    )}
                </Spring>
                {currentStep?.index === 2 && (
                    <Button
                        type="submit"
                        size="sm"
                        full
                        disabled={
                            disabled ||
                            (size ? totalCount !== Number(maxCount) : false)
                        }
                        onClick={
                            user?.subscription?.package === packageId
                                ? (e: any) => handleSubmitProduct(e, e)
                                : (e: any) => handleSubmitBoxProduct(e, e)
                        }
                    >
                        {btnText}
                    </Button>
                )}
                {currentStep?.index === 3 && (
                    <Button
                        type="submit"
                        size="sm"
                        full
                        disabled={disabled}
                        onClick={(e: any) => handleSubmitAddons(e, e)}
                    >
                        {btnText}
                    </Button>
                )}
                {currentStep?.index === 2.1 && (
                    <Button
                        type="submit"
                        size="sm"
                        full
                        disabled={
                            size
                                ? totalCount !== Number(maxCount) || disabled
                                : false
                        }
                        onClick={(e: any) => handleSubmitModifyProduct(e, e)}
                    >
                        {btnText}
                    </Button>
                )}
                {currentStep?.index === 3.1 && (
                    <Button
                        type="submit"
                        size="sm"
                        full
                        disabled={disabled}
                        onClick={(e: any) => handleSubmitModifyAddons(e, e)}
                    >
                        {btnText}
                    </Button>
                )}{' '}
                {currentStep?.index === 4.1 && (
                    <Button
                        type="submit"
                        size="sm"
                        full
                        disabled={
                            size ? totalCount !== Number(maxCount) : false
                        }
                        onClick={(e: any) => handleSubmitModifyBoxProduct(e, e)}
                    >
                        {btnText}
                    </Button>
                )}
            </aside>
            {/* Desktop summary card */}
            <aside className="sticky top-4 mt-24 hidden w-1/5 min-w-[330px] flex-col border border-secondary bg-white p-4 shadow-block lg:flex">
                <h3 className="hidden text-3xl font-bold uppercase md:block">
                    {title}
                </h3>
                {size && onSizeChange && setPackageId && (
                    <>
                        <div
                            role="separator"
                            className="my-4 h-[5px] bg-secondary"
                        />
                        <div>
                            <div>
                                <RadioGroup.Root
                                    value={`${maxCount}`}
                                    onValueChange={(value) => {
                                        onSizeChange(value);
                                        setPackageId(
                                            packages?.filter(
                                                (e: any) => e.id !== packageId
                                            )?.[0].id
                                        );
                                        localStorage.setItem(
                                            'packageId',
                                            packages?.filter(
                                                (e: any) => e.id !== packageId
                                            )?.[0].id
                                        );
                                    }}
                                    className="flex justify-between space-x-6"
                                >
                                    {packages?.map((value: any) => (
                                        <RadioGroup.Item
                                            className="group flex items-center space-x-4"
                                            key={value.id}
                                            value={`${value.selection.maximum}`}
                                        >
                                            <div className="relative h-6 w-6 border border-secondary">
                                                <RadioGroup.Indicator>
                                                    <CheckThickerIcon className="absolute -top-1.5 left-0.5" />
                                                </RadioGroup.Indicator>
                                            </div>
                                            <div className="flex flex-col text-left">
                                                <span className="font-bold uppercase">
                                                    {value.name}
                                                </span>
                                                <span className="text-xs">
                                                    {value.selection.maximum}{' '}
                                                    products
                                                </span>
                                            </div>
                                        </RadioGroup.Item>
                                    ))}
                                </RadioGroup.Root>
                            </div>
                        </div>
                    </>
                )}
                <div role="separator" className="my-4 h-[5px] bg-secondary" />
                <div
                    className={clsx(
                        'max-h-[calc(100vh_-_335px)] overflow-auto',
                        currentStep?.index === 3 &&
                            'max-h-[calc(100vh_-_250px)] '
                    )}
                >
                    {items && items?.[0] && (
                        <div className={clsx('flex flex-1 flex-col')}>
                            {items?.map((item, index) => {
                                if (item)
                                    return (
                                        <div key={item.id}>
                                            <SummaryItem
                                                key={item.id}
                                                {...item}
                                                maxCount={maxCount || 1000}
                                                maxSelection={
                                                    // eslint-disable-next-line no-nested-ternary
                                                    item.inventory !== null
                                                        ? item.inventory! <
                                                          item.selection
                                                              .quantity
                                                            ? item.selection
                                                                  .quantity
                                                            : item.inventory
                                                        : item.selection.maximum
                                                }
                                                error={
                                                    (subscribeProductError as any) ||
                                                    (subscribeAddonsError as any) ||
                                                    (modifyProductError as any) ||
                                                    (modifyAddonsError as any) ||
                                                    (modifyCustomBoxProductError as any)
                                                }
                                                removeCart={removeCart}
                                                product={item}
                                            />
                                            {items?.length !== index + 1 && (
                                                <div
                                                    role="separator"
                                                    className="my-1 block h-[3px] bg-secondary"
                                                />
                                            )}
                                        </div>
                                    );
                                return null;
                            })}
                        </div>
                    )}
                    {currentStep?.index === 3 && (
                        <>
                            <h3 className="mt-8 hidden text-base font-bold uppercase md:block">
                                Your Box
                            </h3>
                            <div
                                role="separator"
                                className="my-1 block h-[3px] bg-secondary"
                            />
                            {products && products?.[0] ? (
                                <div className={clsx('flex flex-1 flex-col')}>
                                    {products?.map(
                                        (product: any, index: any) => {
                                            if (product)
                                                return (
                                                    <div
                                                        key={`product-${product.name}`}
                                                    >
                                                        <SummaryItem
                                                            key={`product-${product.name}`}
                                                            id={`product-${product.name}`}
                                                            name={product.name}
                                                            thumbnail={
                                                                product.thumbnail
                                                            }
                                                            maxCount={
                                                                maxCount || 1000
                                                            }
                                                            lockedCount={
                                                                product.quantity
                                                            }
                                                            locked
                                                            error={
                                                                (subscribeProductError as any) ||
                                                                (subscribeAddonsError as any) ||
                                                                (modifyProductError as any) ||
                                                                (modifyAddonsError as any) ||
                                                                (modifyCustomBoxProductError as any)
                                                            }
                                                        />
                                                        {products?.length !==
                                                            index + 1 && (
                                                            <div
                                                                role="separator"
                                                                className="my-1 block h-[3px] bg-secondary"
                                                            />
                                                        )}
                                                    </div>
                                                );
                                            return null;
                                        }
                                    )}
                                </div>
                            ) : (
                                <div className="my-4 text-xs font-medium uppercase tracking-wide">
                                    {packageInfo?.parents?.[1]}
                                </div>
                            )}
                        </>
                    )}
                </div>
                <div className="flex justify-between py-5">
                    <div className="py-1 font-bold uppercase">
                        {boxPrice ? (
                            <div>
                                {currentStep?.index === 3
                                    ? 'Add-ons Subtotal'
                                    : 'Subtotal'}{' '}
                                : {formatDollarAmount(boxPrice)}
                            </div>
                        ) : (
                            <div>
                                {currentStep?.index === 3
                                    ? 'Add-ons Subtotal'
                                    : 'Subtotal'}{' '}
                                : {formatDollarAmount(subTotal)}
                            </div>
                        )}
                    </div>
                    {size && (
                        <div className="whitespace-nowrap text-3xl font-bold">
                            {totalCount} / {maxCount}
                        </div>
                    )}
                </div>
                {currentStep?.index === 2 && (
                    <Button
                        type="submit"
                        size="sm"
                        full
                        disabled={
                            disabled ||
                            (size ? totalCount !== Number(maxCount) : false)
                        }
                        onClick={
                            user?.subscription?.package === packageId
                                ? (e: any) => handleSubmitProduct(e, e)
                                : (e: any) => handleSubmitBoxProduct(e, e)
                        }
                    >
                        {btnText}
                    </Button>
                )}
                {currentStep?.index === 3 && (
                    <Button
                        type="submit"
                        size="sm"
                        full
                        disabled={disabled}
                        onClick={(e: any) => handleSubmitAddons(e, e)}
                    >
                        {btnText}
                    </Button>
                )}
                {currentStep?.index === 2.1 && (
                    <Button
                        type="submit"
                        size="sm"
                        full
                        disabled={
                            size
                                ? totalCount !== Number(maxCount) || disabled
                                : false
                        }
                        onClick={(e: any) => handleSubmitModifyProduct(e, e)}
                    >
                        {btnText}
                    </Button>
                )}
                {currentStep?.index === 3.1 && (
                    <Button
                        type="submit"
                        size="sm"
                        full
                        disabled={disabled}
                        onClick={(e: any) => handleSubmitModifyAddons(e, e)}
                    >
                        {btnText}
                    </Button>
                )}{' '}
                {currentStep?.index === 4.1 && (
                    <Button
                        type="submit"
                        size="sm"
                        full
                        disabled={
                            size ? totalCount !== Number(maxCount) : false
                        }
                        onClick={(e: any) => handleSubmitModifyBoxProduct(e, e)}
                    >
                        {btnText}
                    </Button>
                )}
            </aside>

            <RedirectAfterErrorModal />
        </>
    );
}
