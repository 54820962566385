import * as AlertDialog from '@radix-ui/react-alert-dialog';
import { XIcon } from '../common/Icons';

interface Props {
    title: string;
    productImage?: string;
    ingredient?: string;
    tips?: string;
    open?: boolean;
    onClose(open: boolean): void;
}

export default function ProductModal({
    title,
    productImage,
    ingredient,
    tips,
    open,
    onClose,
}: Props) {
    return (
        <AlertDialog.Root open={open} onOpenChange={onClose}>
            <AlertDialog.Portal>
                <AlertDialog.Overlay className="fixed inset-0 z-[100] bg-black bg-opacity-25" />
                <AlertDialog.Content className="fixed top-1/2 left-1/2 z-[101] m-auto flex h-auto max-h-[90vh] w-11/12 max-w-md  -translate-y-2/4 -translate-x-2/4 transform flex-col overflow-auto border border-black bg-white text-left align-middle shadow-block transition-all">
                    <AlertDialog.Title className="flex w-[calc(100%_-_40px)] items-center justify-between px-4 pt-4 text-xl font-medium leading-6 text-black">
                        {title}
                    </AlertDialog.Title>
                    <AlertDialog.Cancel
                        id={title}
                        className="absolute top-2 right-2"
                    >
                        <XIcon
                            className="h-6 w-6 text-black"
                            onClick={() => onClose(false)}
                        />
                    </AlertDialog.Cancel>
                    <div className="mt-4 lg:mt-6 xl:mt-10">
                        <img
                            className="h-auto w-full"
                            src={productImage}
                            alt=""
                        />
                    </div>
                    <div className="space-y-4 py-3 px-4 text-lg font-medium leading-6 text-black lg:space-y-6">
                        <div className="space-y-1 lg:space-y-2">
                            <div className="text-[19px] font-medium lg:text-[22px]">
                                Ingredients & Nutritional Information
                            </div>
                            <div className="text-[14px] font-normal lg:text-base">
                                {ingredient}
                            </div>
                        </div>
                        <div className="space-y-1 lg:space-y-2">
                            <div className="text-[19px] font-medium lg:text-[22px]">
                                Cooking Tips
                            </div>
                            <div className="text-[14px] font-normal lg:text-base">
                                {tips}
                            </div>
                        </div>
                    </div>
                </AlertDialog.Content>
            </AlertDialog.Portal>
        </AlertDialog.Root>
    );
}
