import { InferType, mixed, object } from 'yup';
import Product from '@/types/Product';

export const subscribeAddonsFormSchema = object({
    choices: mixed<Product[]>().label('Products').required(),
});

type SubscribeAddonsFormModel = InferType<typeof subscribeAddonsFormSchema>;

export default SubscribeAddonsFormModel;
