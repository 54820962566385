import * as RadioGroup from '@radix-ui/react-radio-group';
import { Field, FieldProps } from 'formik';
import Button from '@/components/common/Button';
import Form from '@/components/forms/Form';
import BoxRadio from '@/components/inputs/BoxRadio';
import BoxTypeFormModel from '@/models/BoxTypeFormModel';
import { makeDot } from '@/types/Path';
import BestValueDownArrow from '@svg/best-value-down-arrow.svg';
import BestValueLeftArrow from '@svg/best-value-left-arrow.svg';

const dot = makeDot<BoxTypeFormModel>();
const MeatBox2 = `${window.cdn}meatbox2.png`;
const MeatBox = `${window.cdn}meatbox.png`;

type ChooseYourBoxTypeFormProps = {
    selected?: any;
    setSelected(value: any): void;
    setSelectedBox(value: any): void;
    packagesResult: any;
};

export default function ChooseYourBoxTypeForm({
    selected,
    setSelected,
    setSelectedBox,
    packagesResult,
}: ChooseYourBoxTypeFormProps) {
    return (
        <Form className="flex w-full flex-col items-center">
            <Field name={dot('root')}>
                {({ field, form }: FieldProps) => (
                    <RadioGroup.Root
                        {...field}
                        value={selected?.id}
                        onValueChange={(value: any) => {
                            form.setFieldValue('root', value.id);
                            setSelected(value.id);
                            setSelectedBox(value.name);
                        }}
                        className="relative mx-auto mb-12 mt-4 flex w-full max-w-3xl flex-col items-center sm:space-y-4 md:mt-0 md:mb-0 md:flex-row md:items-stretch md:space-x-4 md:space-y-0"
                    >
                        {packagesResult?.map((e: any) => (
                            <BoxRadio
                                key={e.id}
                                heading={e.name}
                                title={
                                    e.name === 'Custom Box'
                                        ? 'Build your own'
                                        : 'Curated by us'
                                }
                                value={e}
                                image={
                                    e.name === 'Custom Box' ? MeatBox : MeatBox2
                                }
                                imageAlt="A person puts meat in a box"
                                after={
                                    e.name === 'Custom Box' && (
                                        <>
                                            <BestValueLeftArrow className="left absolute bottom-full -mb-8 hidden md:right-[70%] md:-mb-2 md:block md:rotate-[30deg] lg:right-full lg:-mb-8 lg:rotate-0" />
                                            <BestValueDownArrow className="absolute bottom-full right-0 mb-2 md:hidden" />
                                        </>
                                    )
                                }
                                modal={e.description}
                            >
                                {e.description && (
                                    <div
                                        className="modal-tiny"
                                        // eslint-disable-next-line react/no-danger
                                        dangerouslySetInnerHTML={{
                                            __html: e.description,
                                        }}
                                    />
                                )}
                            </BoxRadio>
                        ))}
                    </RadioGroup.Root>
                )}
            </Field>
            <Button type="submit" disabled={!selected} wide className="my-12">
                Next step
            </Button>
        </Form>
    );
}
