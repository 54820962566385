import * as RadioGroup from '@radix-ui/react-radio-group';
import { Field, FieldProps } from 'formik';
import Button from '@/components/common/Button';
import Form from '@/components/forms/Form';
import AccordionSelect from '@/components/inputs/AccordionSelect';
import BoxSizeRadio from '@/components/inputs/BoxSizeRadio';
import BoxSizeFormModel from '@/models/BoxSizeFormModel';
import { makeDot } from '@/types/Path';
import formatDollarAmount from '@/utils/formatDollarAmount';
import { getDate, getTime } from '@/utils/luxonFormats';
import BestValueDownLeftArrowSvg from '@svg/best-value-down-left-arrow.svg';
import BestValueUpLeftArrowSvg from '@svg/best-value-up-left-arrow.svg';
import UnderlineSvg from '@svg/underline.svg';

const dot = makeDot<BoxSizeFormModel>();

type ChooseYourBoxSizeFormProps = {
    isValid: boolean;
    childPackagesResult: any;
    deliveryFrequencyResult?: any;
    selected?: any;
    setSelected(value: any): void;
    startedAt?: string;
    endedAt?: string;
    defaultFrequency?: number;
};

export default function ChooseYourBoxSizeForm({
    isValid,
    childPackagesResult,
    deliveryFrequencyResult,
    selected,
    setSelected,
    startedAt,
    endedAt,
    defaultFrequency,
}: ChooseYourBoxSizeFormProps) {
    return (
        <Form className="flex w-full flex-col items-center">
            <Field name={dot('package')}>
                {({ field, form }: FieldProps) => (
                    <RadioGroup.Root
                        {...field}
                        value={selected?.id}
                        onValueChange={(value: any) => {
                            form.setFieldValue('package', value.id);
                            setSelected(value.id);
                        }}
                        className="relative mx-auto mb-12 flex w-full max-w-3xl flex-col items-center md:mb-0 md:flex-row md:items-stretch"
                    >
                        {childPackagesResult?.packages?.map((e: any) => (
                            <BoxSizeRadio
                                key={e.id}
                                id={e.id}
                                value={e}
                                title={e.name}
                                heading={`From ${formatDollarAmount(
                                    e?.price?.value
                                )}`}
                                childPackagesName={childPackagesResult.name}
                                modal={
                                    e.description ||
                                    childPackagesResult.name !== 'Custom Box'
                                }
                                description={e.description}
                            >
                                {e.name === 'Small Box' ? (
                                    <ul className="ml-4 flex list-disc flex-col  text-sm uppercase tracking-wider [&>li]:mb-1">
                                        {childPackagesResult.name ===
                                        'Custom Box' ? (
                                            <>
                                                <li>2-3 people</li>
                                                <li>Up to 6kg of meat</li>
                                                <li>
                                                    <div className="relative inline-block font-bold">
                                                        Free shipping
                                                        <UnderlineSvg className="absolute -bottom-1 left-0 right-0 h-1.5 w-full" />
                                                    </div>
                                                </li>
                                            </>
                                        ) : (
                                            <>
                                                <li>2-3 people</li>
                                                {childPackagesResult.name ===
                                                '100% Wild Salmon Box' ? (
                                                    <li>
                                                        10 INDIVIDUALLY SEALED
                                                        PORTIONS
                                                    </li>
                                                ) : (
                                                    <li>Up to 5kg of meat</li>
                                                )}
                                                <li>
                                                    <div className="relative inline-block font-bold">
                                                        Free shipping
                                                        <UnderlineSvg className="absolute -bottom-1 left-0 right-0 h-1.5 w-full" />
                                                    </div>
                                                </li>
                                            </>
                                        )}
                                    </ul>
                                ) : (
                                    <>
                                        <BestValueDownLeftArrowSvg
                                            aria-label="best value"
                                            role="img"
                                            className="absolute bottom-full right-0 mb-4 hidden md:block lg:-right-20"
                                        />
                                        <BestValueUpLeftArrowSvg
                                            aria-label="best value"
                                            role="img"
                                            className="absolute top-full right-0 -mt-5 md:hidden"
                                        />
                                        {childPackagesResult.name ===
                                        'Custom Box' ? (
                                            <ul className="ml-4 flex list-disc flex-col text-sm uppercase tracking-widest [&>li]:mb-1">
                                                <li>4-5 people</li>
                                                <li>Up to 11kg of meat</li>
                                                <li>
                                                    <div className="relative inline-block font-bold">
                                                        Free shipping
                                                        <UnderlineSvg className="absolute -bottom-1 left-0 right-0 h-1.5 w-full" />
                                                    </div>
                                                </li>
                                            </ul>
                                        ) : (
                                            <ul className="ml-4 flex list-disc flex-col text-sm uppercase tracking-widest [&>li]:mb-1">
                                                <li>3-4 people</li>
                                                {childPackagesResult.name ===
                                                '100% Wild Salmon Box' ? (
                                                    <li>
                                                        20 INDIVIDUALLY SEALED
                                                        PORTIONS
                                                    </li>
                                                ) : (
                                                    <li>Up to 9kg of meat</li>
                                                )}
                                                <li>
                                                    <div className="relative inline-block font-bold">
                                                        Free shipping
                                                        <UnderlineSvg className="absolute -bottom-1 left-0 right-0 h-1.5 w-full" />
                                                    </div>
                                                </li>
                                            </ul>
                                        )}
                                    </>
                                )}
                            </BoxSizeRadio>
                        ))}
                    </RadioGroup.Root>
                )}
            </Field>
            {deliveryFrequencyResult && (
                <div>
                    <h3 className="mb-2 mt-8 text-center text-3xl font-bold">
                        Choose your frequency
                    </h3>
                    <p className="mb-4 text-center text-xs uppercase leading-5 tracking-widest">
                        You can skip, pause or
                        <br />
                        cancel any time
                    </p>
                    <div className="flex flex-col items-center text-xl">
                        {defaultFrequency && (
                            <Field name={dot('frequency')}>
                                {({ form }: FieldProps) => (
                                    <AccordionSelect
                                        className="w-full"
                                        label="We'll send you a box every"
                                        onChange={(value) =>
                                            form.setFieldValue(
                                                dot('frequency'),
                                                value
                                            )
                                        }
                                        options={deliveryFrequencyResult}
                                        defaultValue={String(defaultFrequency)}
                                    />
                                )}
                            </Field>
                        )}
                        <div className="mt-4 text-center">
                            Your first delivery will be{' '}
                            <strong className="ml-1">
                                {getDate(startedAt)} between{' '}
                                {getTime(startedAt)} - {getTime(endedAt)}
                            </strong>
                        </div>
                    </div>
                </div>
            )}
            <Button type="submit" wide disabled={!isValid} className="my-12">
                Next step
            </Button>
        </Form>
    );
}
