import client from './client';

const { post, patch } = client;

export type ResetPasswordParams = {
    token: string;
    email: string;
    password: string;
    passwordConfirmation: string;
};

export type ModifyPasswordParams = {
    oldPassword: string;
    password: string;
    passwordConfirmation: string;
};

export type ForgotPasswordParams = {
    email: string;
};

export const postForgotPassword = (data: ForgotPasswordParams) =>
    post('forgot-password', data);
export const postResetPassword = (data: ResetPasswordParams) =>
    post('/reset-password', data);
export const patchModifyPassword = (data: ModifyPasswordParams) =>
    patch('/api/me/password', data);
