import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { XIcon } from '../common/Icons';

export default function NotificationCard() {
    // eslint-disable-next-line react/no-unstable-nested-components, react/function-component-definition
    const CloseButton = ({ closeToast }: any) => (
        <XIcon
            className="absolute right-[10px] top-[30px] h-4 w-4 align-middle"
            onClick={closeToast}
        />
    );

    return <ToastContainer closeButton={CloseButton} />;
}
