import clsx from 'clsx';
import SummaryItem from '@/components/common/SummaryItem';
import { useProductsPackagesWithoutSubscription } from '@/services/deliveryZoneService';

type Props = {
    description: string | undefined;
    packageId: string;
    childPackagesName?: string;
};

export default function ModalBoxSizeContent({
    description,
    packageId,
    childPackagesName,
}: Props) {
    const { productsPackagesResult } = useProductsPackagesWithoutSubscription(
        packageId,
        childPackagesName!
    );

    return (
        <div className="space-y-4">
            {description && (
                <div
                    className="modal-tiny"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                        __html: description,
                    }}
                />
            )}
            {productsPackagesResult && productsPackagesResult?.products && (
                <div>
                    <h3 className="mt-2 mb-1 font-neue text-[14px] font-bold text-black lg:text-base">
                        What you would receive in this box:
                    </h3>
                    <div className={clsx('flex flex-1 flex-col')}>
                        {productsPackagesResult?.products.map(
                            (product: any, index: any) => {
                                if (
                                    productsPackagesResult?.length !==
                                    index + 1
                                )
                                    return (
                                        <div key={`product-${product.name}`}>
                                            <SummaryItem
                                                id={`product-${product.name}`}
                                                name={product.name}
                                                thumbnail={product.thumbnail}
                                                maxCount={
                                                    product?.selection?.maximum
                                                }
                                                lockedCount={
                                                    product?.selection?.maximum
                                                }
                                                locked
                                            />
                                            <div
                                                role="separator"
                                                className="my-2 h-[1px] bg-secondary"
                                            />
                                        </div>
                                    );
                                return (
                                    <SummaryItem
                                        key={`product-${product.name}`}
                                        id={`product-${product.name}`}
                                        name={product.name}
                                        thumbnail={product.thumbnail}
                                        maxCount={product?.selection?.maximum}
                                        lockedCount={
                                            product?.selection?.maximum
                                        }
                                        locked
                                    />
                                );
                            }
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}
