import { InferType, mixed, object, string } from 'yup';
import Product from '@/types/Product';

export const updateCustomBoxTypeFormSchema = object({
    root: string(),
    package: string(),
    choices: mixed<Product[]>().label('Products').required(),
});

type UpdateCustomBoxTypeFormModel = InferType<
    typeof updateCustomBoxTypeFormSchema
>;

export default UpdateCustomBoxTypeFormModel;
