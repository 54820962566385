import { useMutation, useQuery } from '@tanstack/react-query';
import { decamelize } from 'humps';
import ProductModel from '@/models/ProductModel';
import { formatToUtc4 } from '@/utils/dateFormats';
import queryClient from '@/utils/queryClient';
import client from './client';

type ModifySubscriptionParams = any;

function compareObjects(obj1: any, obj2: any) {
    const propsToCompare = [
        'firstName',
        'lastName',
        'addressType',
        'addressLine_1',
        'addressLine_2',
        'company',
        'postcode',
        'city',
        'state',
        'notes',
        'deliverySchedule',
        'frequency',
    ];

    // eslint-disable-next-line no-restricted-syntax
    for (const prop of propsToCompare) {
        if (obj1[prop] !== obj2[prop] || `+${obj1.phone}` !== obj2.phone) {
            return false;
        }
    }
    return true;
}

function cleanPayload(initialValues: any, payload: any) {
    if (compareObjects(initialValues, payload)) {
        delete payload.firstName;
        delete payload.lastName;
        delete payload.phone;
        delete payload.addressLine_1;
        delete payload.addressLine_2;
        delete payload.addressType;
        delete payload.company;
        delete payload.city;
        delete payload.country;
        delete payload.postcode;
        delete payload.state;
        delete payload.notes;
        delete payload.deliverySchedule;
        delete payload.frequency;
        delete payload.paymentMethod;
    } else {
        delete payload.id;
    }

    return payload;
}

export const getSubscriptions = () => client.get<any>('/api/subscriptions');

export function useSubscriptions() {
    const query = useQuery(
        ['deliveryBox'],
        async () => {
            const { data } = await getSubscriptions();
            return data;
        },
        {
            retry: false,
        }
    );
    const { data: subscriptionsResult, ...queryResult } = query;
    return { subscriptionsResult, ...queryResult };
}

export const getSubscriptionDetails = (subscription: string, date: string) =>
    client.get<any>(`/api/subscriptions/${subscription}/details/${date}`);

export function useSubscriptionsDetails(subscription: string, date: string) {
    const query = useQuery(
        [`${subscription}${date}`, date],
        async () => {
            const { data } = await getSubscriptionDetails(subscription, date);
            return data;
        },
        {
            retry: false,
        }
    );

    const { data: subscriptionDetailsResult, ...queryResult } = query;
    return { subscriptionDetailsResult, ...queryResult };
}

export const pauseSubscription = (subscription: string, data: any) =>
    client.post(`/api/subscriptions/${subscription}/pause`, data, {
        transformRequest: [
            (d: any) => {
                const payload: any = new FormData();
                Object.entries(d).forEach(([key, value]) => {
                    if (value) {
                        const utc4DateString = formatToUtc4(value);
                        payload.append(decamelize(key), utc4DateString);
                    }
                });
                return payload;
            },
        ],
    });

export function usePauseSubscription(
    subscription: string,
    navigate: any,
    toast: any
) {
    return useMutation(
        async (payload: any) => {
            navigate('/my-subscriptions');
            const { data } = await pauseSubscription(subscription, payload);
            return data;
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['deliveryBox']);
                queryClient.invalidateQueries(['singleMenu']);
                queryClient.removeQueries(['menu']);
                toast();
            },
        }
    );
}

export const unResumeSubscription = (subscription: string) =>
    client.post(`/api/subscriptions/${subscription}/resume`);

export function useResumeSubscription(subscription: string) {
    const query = useMutation(
        async () => {
            const { data } = await unResumeSubscription(subscription);
            return data;
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['deliveryBox']);
                queryClient.invalidateQueries(['singleMenu']);
                queryClient.removeQueries(['menu']);
            },
        }
    );
    const { data: resumeSubscriptionResult, ...queryResult } = query;
    return { resumeSubscriptionResult, ...queryResult };
}

export const cancelSubscription = (subscription: string, data: any) =>
    client.post(`/api/subscriptions/${subscription}/cancel`, { reasons: data });
export function useCancelSubscription(subscription: string) {
    return useMutation(
        async (payload: any) => {
            const { data } = await cancelSubscription(subscription, payload);
            return data;
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['deliveryBox']);
                queryClient.removeQueries(['menu']);
            },
        }
    );
}

export const modifySubscription = (
    subscription: string,
    data: ModifySubscriptionParams
) => client.patch<any>(`/api/subscriptions/${subscription}`, data);

export const modifySubscriptionPayment = (subscription: string, data: any) =>
    client.put(`/api/subscriptions/${subscription}/payment-method`, data);

export function useModifySubscription(subscription: string, initalValues: any) {
    return useMutation(
        async (payload: ModifySubscriptionParams) => {
            const newPayload = cleanPayload(initalValues, payload);
            if (newPayload.id) {
                const { data } = await modifySubscriptionPayment(
                    subscription,
                    newPayload
                );
                return data;
            }
            if (payload.notes === '') {
                delete payload.notes;
            }
            if (payload.company === '') {
                delete payload.company;
            }
            if (payload.addressLine_2 === '') {
                delete payload.addressLine_2;
            }
            const { data } = await modifySubscription(subscription, newPayload);
            return data;
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['deliveryBox']);
                queryClient.invalidateQueries(['singleMenu']);
                queryClient.removeQueries(['menu']);
            },
        }
    );
}

export const getModifyPackageProduct = (
    subscription: string,
    subscriptionPackage: string,
    date: string
) =>
    client.get<ProductModel[]>(
        `/api/subscriptions/${subscription}/packages/${subscriptionPackage}/products/${date}`
    );

export function useModifyProducts(
    subscription: string,
    packageId: string,
    date: string
) {
    const query = useQuery(
        ['products', subscription],
        async () => {
            const { data } = await getModifyPackageProduct(
                subscription,
                packageId,
                date
            );
            return data;
        },
        {
            retry: false,
        }
    );

    const { data: productsResult, ...queryResult } = query;
    return { productsResult, ...queryResult };
}

export const getModifyAddOns = (subscription: string, date: string) =>
    client.get<ProductModel[]>(
        `/api/subscriptions/${subscription}/add-ons/${date}`
    );

export function useModifyAddOns(subscription: string, date: string) {
    const query = useQuery(
        ['productsaddons'],
        async () => {
            const { data } = await getModifyAddOns(subscription, date);
            return data;
        },
        {
            retry: false,
        }
    );

    const { data: productsAddonsResult, ...queryResult } = query;
    return { productsAddonsResult, ...queryResult };
}

export const modifyCuratedBox = (subscription: string, data: any) =>
    client.put<any>(
        window.Echo.socketId()
            ? `/api/subscriptions/${subscription}/packages`
            : `/api/socketless/subscriptions/${subscription}/packages`,
        data
    );

export function useModifyCuratedBox(subscription: string) {
    return useMutation(async (payload: any) => {
        const { data } = await modifyCuratedBox(subscription, payload);
        return data;
    });
}

export const modifyCustomBox = (subscription: string, data: any) =>
    client.put<any>(
        window.Echo.socketId()
            ? `/api/subscriptions/${subscription}/packages`
            : `/api/socketless/subscriptions/${subscription}/packages`,
        data
    );

export function useModifyCustomBox(subscription: string, payload: any) {
    return useMutation(async () => {
        const { data } = await modifyCustomBox(subscription, payload);
        return data;
    });
}

export const getDeliverySchedule = (postcode: string) =>
    client.get<any>(`/api/delivery-zones/${postcode}`);
