import { InferType, number, object, string } from 'yup';

export const boxSizeFormSchema = object({
    package: string().required(),
    frequency: number().required(),
});

type BoxSizeFormModel = InferType<typeof boxSizeFormSchema>;

export default BoxSizeFormModel;
