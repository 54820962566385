import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProductModel from '@/models/ProductModel';
import { selectCurrentStep } from '@/store/getStarted/getStartedSlice';
import { RootState } from '@/store/types';
import {
    selectItemCount,
    selectTotalCount,
    setItemCount,
} from '@/store/yourItems/yourItemsSlice';
import Button from '../common/Button';
import ProductCountInput from '../inputs/ProductCountInput';
import ProductModal from '../modals/ProductModal';

type Props = {
    product: ProductModel;
    maxCount: number;
    removeCart?: any;
};

export default function ProductSelectCard({
    product,
    maxCount,
    removeCart,
}: Props) {
    const {
        id,
        name,
        content,
        thumbnail,
        weight,
        portion,
        inventory,
        selection,
    } = product;
    const dispatch = useDispatch();
    const count: any = useSelector((state: RootState) =>
        selectItemCount(state, id)
    );
    const currentStep = useSelector(selectCurrentStep);
    const totalCount = useSelector(selectTotalCount);
    const available = maxCount - totalCount;
    const [openModal, setOpenModal] = useState(false);

    return (
        <div className="flex cursor-pointer flex-col justify-between border border-secondary p-3 text-left hover:shadow-block xs:p-2 sm:p-3 lg:p-4">
            <h4 className="text-lg font-bold !leading-6 xs:text-sm xs:!leading-4 sm:text-lg sm:!leading-6 xl:text-xl">
                {name}
            </h4>
            <div>
                <div className="group relative w-full">
                    <img
                        className="mt-8 w-full object-cover object-center xs:mt-4 sm:mt-8"
                        src={thumbnail}
                        alt=""
                    />
                    <div
                        tabIndex={0}
                        role="button"
                        className="absolute inset-0 m-auto hidden h-8 w-48 items-center justify-center bg-secondary font-agrandir text-xs font-bold uppercase tracking-widest text-white group-hover:inline-flex"
                        onClick={() => {
                            setOpenModal(true);
                        }}
                        onKeyDown={(event) => {
                            if (event.key === 'enter' || event.key === 'space')
                                setOpenModal(true);
                        }}
                    >
                        More info
                    </div>
                </div>
                <div className="flex min-h-[78px] items-end justify-between">
                    <div className="flex flex-col text-sm xs:text-xs sm:text-sm">
                        {weight && (
                            <dl className="my-0.5 flex flex-wrap">
                                <dt className="mr-1 uppercase after:ml-1 after:content-[':']">
                                    Weight
                                </dt>
                                <dd className="font-medium">{weight}</dd>
                            </dl>
                        )}
                        {portion && (
                            <dl className="my-0.5 flex flex-wrap">
                                <dt className="mr-1 uppercase after:ml-1 after:content-[':']">
                                    Portion
                                </dt>
                                <dd className="font-medium">{portion}</dd>
                            </dl>
                        )}
                        {selection?.maximum && (
                            <dl className="my-0.5 flex flex-wrap">
                                <dt className="mr-1 uppercase after:ml-1 after:content-[':']">
                                    Max
                                </dt>
                                <dd className="font-medium">
                                    {selection?.maximum} per box
                                </dd>
                            </dl>
                        )}
                    </div>
                    {count && count !== 0 ? (
                        <ProductCountInput
                            id={id}
                            count={
                                // eslint-disable-next-line no-nested-ternary
                                currentStep.index === 4.1 && inventory
                                    ? selection.quantity > inventory
                                        ? inventory
                                        : selection.quantity
                                    : count
                            }
                            available={available}
                            maxSelection={
                                // eslint-disable-next-line no-nested-ternary
                                currentStep.index === 4.1 && inventory
                                    ? selection.quantity > inventory
                                        ? inventory
                                        : selection.quantity
                                    : (inventory as any) > selection.quantity ||
                                      inventory === null
                                    ? selection.maximum || inventory
                                    : selection.quantity
                            }
                            onCountChange={(value) => {
                                dispatch(setItemCount({ id, count: value }));
                            }}
                            removeCart={removeCart}
                            product={product}
                        />
                    ) : (
                        <Button
                            size="sm"
                            className="!font-neue font-bold tracking-normal"
                            disabled={available <= 0}
                            onClick={() =>
                                dispatch(setItemCount({ id, count: 1 }))
                            }
                        >
                            + Add
                        </Button>
                    )}
                </div>
            </div>
            <ProductModal
                title={name}
                productImage={thumbnail}
                ingredient={content.ingredients}
                tips={content.cookingTips}
                open={openModal}
                onClose={setOpenModal}
            />
        </div>
    );
}
