import { InferType, mixed, object } from 'yup';
import Product from '@/types/Product';

export const subscribeProductFormSchema = object({
    choices: mixed<Product[]>().label('Products').required(),
});

type SubscribeProductFormModel = InferType<typeof subscribeProductFormSchema>;

export default SubscribeProductFormModel;
