import * as RadioGroup from '@radix-ui/react-radio-group';
import { Field, FieldProps } from 'formik';
import Button from '@/components/common/Button';
import {
    WildCaughtBottomIcon,
    WildCaughtIcon,
} from '@/components/common/Icons';
import Form from '@/components/forms/Form';
import BoxRadio from '@/components/inputs/BoxRadio';
import BoxTypeFormModel from '@/models/BoxTypeFormModel';
import { useUser } from '@/store/user/userSlice';
import { makeDot } from '@/types/Path';

const dot = makeDot<BoxTypeFormModel>();
const BeefBox = `${window.cdn}BeefBox.jpg`;
const BeefChickenBox = `${window.cdn}BeefChickenBox.jpg`;
const BeefPorcBox = `${window.cdn}BeefPorcBox.jpg`;
const MixedBox = `${window.cdn}MixedBox.jpg`;
const SalmonBox = `${window.cdn}SalmonBox.jpg`;
const ValueBox = `${window.cdn}ValueBox.jpg`;
const MeatBox = `${window.cdn}meatbox.png`;

type ChooseYourCurratedBoxFormProps = {
    selected?: string;
    setSelected(value: string): void;
    childPackagesResult: any;
};

function boxImage(packageName: string) {
    if (packageName === '100% Wild Salmon Box') {
        return SalmonBox;
    }
    if (packageName === 'The Beef Box') {
        return BeefBox;
    }
    if (packageName === 'The Beef & Chicken Box') {
        return BeefChickenBox;
    }
    if (packageName === 'The Beef & Pork Box') {
        return BeefPorcBox;
    }
    if (packageName === 'The Mixed Box') {
        return MixedBox;
    }
    if (packageName === 'The Value Box') {
        return ValueBox;
    }
    return MeatBox;
}

export default function ChooseYourCurratedBoxForm({
    selected,
    setSelected,
    childPackagesResult,
}: ChooseYourCurratedBoxFormProps) {
    const [user] = useUser();
    return (
        <Form className="flex w-full flex-col items-center">
            <Field name={dot('pivot')}>
                {({ field, form }: FieldProps) => (
                    <RadioGroup.Root
                        {...field}
                        value={selected}
                        onValueChange={(value) => {
                            form.setFieldValue('pivot', value);
                            setSelected(value);
                        }}
                        className="relative flex flex-col sm:grid sm:grid-cols-2 sm:gap-4"
                    >
                        {user?.subscription?.postcode?.startsWith('7') ||
                        localStorage
                            .getItem('subscriptionPostcode')
                            ?.startsWith('7') ? (
                            // eslint-disable-next-line react/jsx-no-useless-fragment
                            <>
                                {childPackagesResult?.packages.map((e: any) => {
                                    if (
                                        !e.name.toLowerCase().includes('salmon')
                                    ) {
                                        return (
                                            <BoxRadio
                                                key={e.id}
                                                heading={e.name}
                                                title={
                                                    e.name === 'Custom Box'
                                                        ? 'Build your own'
                                                        : 'Curated by us'
                                                }
                                                value={e.id}
                                                image={boxImage(e.name)}
                                                imageAlt="Box filed with protein"
                                                after={
                                                    e.name ===
                                                        '100% Wild Salmon Box' && (
                                                        <>
                                                            <WildCaughtBottomIcon className="absolute bottom-full left-0 -ml-8 -mb-2 block lg:hidden" />
                                                            <WildCaughtIcon className="absolute top-0 right-full -mt-10 -mr-6 hidden h-[235px] w-[190px] lg:block xl:mr-0 xl:h-[235px] xl:w-[230px]" />
                                                        </>
                                                    )
                                                }
                                                modal={e.description}
                                            >
                                                {e.description && (
                                                    <div
                                                        className="modal-tiny"
                                                        // eslint-disable-next-line react/no-danger
                                                        dangerouslySetInnerHTML={{
                                                            __html: e.description,
                                                        }}
                                                    />
                                                )}
                                            </BoxRadio>
                                        );
                                    }
                                    return null;
                                })}
                            </>
                        ) : (
                            // eslint-disable-next-line react/jsx-no-useless-fragment
                            <>
                                {childPackagesResult?.packages.map((e: any) => (
                                    <BoxRadio
                                        key={e.id}
                                        heading={e.name}
                                        title={
                                            e.name === 'Custom Box'
                                                ? 'Build your own'
                                                : 'Curated by us'
                                        }
                                        value={e.id}
                                        image={boxImage(e.name)}
                                        imageAlt="Box filed with protein"
                                        after={
                                            e.name ===
                                                '100% Wild Salmon Box' && (
                                                <>
                                                    <WildCaughtBottomIcon className="absolute bottom-full left-0 -ml-8 -mb-2 block lg:hidden" />
                                                    <WildCaughtIcon className="absolute top-0 right-full -mt-10 -mr-6 hidden h-[235px] w-[190px] lg:block xl:mr-0 xl:h-[235px] xl:w-[230px]" />
                                                </>
                                            )
                                        }
                                        modal={e.description}
                                    >
                                        {e.description && (
                                            <div
                                                className="modal-tiny"
                                                // eslint-disable-next-line react/no-danger
                                                dangerouslySetInnerHTML={{
                                                    __html: e.description,
                                                }}
                                            />
                                        )}
                                    </BoxRadio>
                                ))}
                            </>
                        )}
                    </RadioGroup.Root>
                )}
            </Field>
            <Button type="submit" disabled={!selected} wide className="my-12">
                Next step
            </Button>
        </Form>
    );
}
